import React from 'react';

const Spinner = ({ message }) => {
    return (
        <div className="flex flex-col justify-center items-center space-y-2 h-screen">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-red-500"></div>
            <p className="text-lg text-slate-500">{message}</p>
        </div>
    );
};

export default Spinner;
