import React from 'react';
import GoogleLoginButton from '../components/GoogleLoginButton';

const Login = (props) => {

    const { onLoginSuccess, onLoginFailure, protocol, url, frontendPort, serverPort } = props;

    return (
        <div className="flex flex-col items-center justify-center w-full py-10 md:h-screen">
            <div className="flex flex-col items-center space-y-4 mt-6">
                <img src={`${protocol}${url}:${frontendPort}/pivot-favicon-source.png`} alt="PIVOT company logo" className="w-12 md:w-16 mb-2" />
                <img src={`${protocol}${url}:${frontendPort}/flow.png`} alt="Flow logo" className="w-3/4 sm:w-2/5 md:w-2/5 lg:w-2/5 mb-2" />
                <div className="text-white rounded max-w-xs mx-auto">
                    <GoogleLoginButton 
                        onSuccess={onLoginSuccess}
                        onFailure={onLoginFailure}
                        protocol={protocol}
                        url={url}
                        serverPort={serverPort}
                    />
                </div>
            </div>
        </div>
    );
};

export default Login;
