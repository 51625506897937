import React from 'react';
import axios from 'axios';

const GoogleLoginButton = (props) => {

    const { onFailure, protocol, url, serverPort } = props;

    const handleLogin = async () => {
        try {
            // Get the auth URL from your backend
            const response = await axios.get(`${protocol}${url}:${serverPort}/api/auth/authURL`);
            const authUrl = response.data;

            // Redirect the user to the auth URL
            window.location.href = authUrl;
        } catch (error) {
            console.error('Error fetching authentication URL:', error);
            onFailure(error);
        }
    };

    return (
        <button
            onClick={handleLogin}
            className="bg-[#e6251e] hover:bg-[#fc0f06] text-white font-bold p-4 px-4 rounded w-full"
        >
            Login with Google
        </button>
    );
};

export default GoogleLoginButton;
