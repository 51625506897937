import React from 'react';
import { useLocation } from 'react-router-dom';

const Error = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const message = params.get('message');

    return (
        <div className="flex flex-col items-center justify-center h-screen error">
            <h1 className="text-2xl font-bold mb-4">Error</h1>
            <p className="text-lg text-red-500">{decodeURIComponent(message)}</p>
        </div>
    );
};

export default Error;