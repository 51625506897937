import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import axios from 'axios';
import Home from './views/Home';
import Login from './views/Login';
import Error from './views/Error';
import Spinner from './components/Spinner';
const protocol = process.env.REACT_APP_FRONTEND_PROTOCOL;
const url = process.env.REACT_APP_FRONTEND_URL;
const frontendPort = process.env.REACT_APP_FRONTEND_PORT;
// const serverProtocol = process.env.REACT_APP_SERVER_PROTOCOL;
// const serverUrl = process.env.REACT_APP_SERVER_URL;
const serverPort = process.env.REACT_APP_SERVER_PORT;

function App() {
    const [dealsData, setDealsData] = useState({ open: {}, won: {}, lost: {} });
    // const [loading, setLoading] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Function to check authentication and fetch data
        const checkAndFetchData = async () => {
                try {
                    const response = await axios.get(`${protocol}${url}:${serverPort}/api/d`, {
                        withCredentials: true
                    });
                    setDealsData(response.data);
                    if (response.data) {
                        setIsAuthenticated(true);
                    }
                    // console.log(response.data);
                } catch (error) {
                    console.error('Error:', error.response ? error.response.data : error);
                    setIsAuthenticated(false);
                    setError(error.response ? error.response.data : error);
                } finally {
                    setIsDataFetched(true);
                };
        };

        checkAndFetchData();
    }, []);

    const handleLoginSuccess = (token) => {
        console.log('Login Successful:', token);
        setIsAuthenticated(true);
    };

    const handleLoginFailure = (error) => {
        console.error('Login Failed:', error);
    };

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element=
                    {<Login 
                        onLoginSuccess={handleLoginSuccess} 
                        onLoginFailure={handleLoginFailure}
                        protocol={protocol}
                        url={url}
                        frontendPort={frontendPort}
                        serverPort={serverPort}
                    />} />
                <Route path="/error" element={<Error />} />
                <Route path="/" element={isDataFetched ? (isAuthenticated ? <Home dealsData={dealsData} /> : <Navigate to="/login" />) : <Spinner message="Building Report..." />} />
            </Routes>
            {error && <div className="error">{error.message}</div>}
        </BrowserRouter>
    );
};

export default App;