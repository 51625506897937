import React from 'react';

const Summary = ({ dealsData }) => {
    let averageSalesCycle = 0;

    if (dealsData.won && Array.isArray(dealsData.won.deals) && dealsData.won.deals.length > 0) {
        const totalSalesCycle = dealsData.won.deals
            .reduce((acc, deal) => acc + (deal.salesCycleDuration || 0), 0);
        averageSalesCycle = totalSalesCycle / dealsData.won.deals.length;
    }

    return (
        <div className="bg-white py-10">
            <h2 className="px-4 text-lg font-semibold leading-7 text-black sm:px-6 lg:px-8 text-left">Lead Summary - Last 30 Days</h2>
            <table className="mt-6 w-full whitespace-nowrap text-left">
                <thead className="border-b border-gray-200 text-sm leading-6 text-gray-700">
                    <tr>
                        <th scope="col" className="py-2 px-4 font-semibold">Deal Status</th>
                        <th scope="col" className="py-2 px-4 font-semibold">Count</th>
                        <th scope="col" className="py-2 px-4 font-semibold">Average Sales Cycle (days)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="py-2 px-4">Open Deals</td>
                        <td className="py-2 px-4">{dealsData.open?.count || 0}</td>
                        <td className="py-2 px-4">-</td>
                    </tr>
                    <tr>
                        <td className="py-2 px-4">Won Deals</td>
                        <td className="py-2 px-4">{dealsData.won?.count || 0}</td>
                        <td className="py-2 px-4">{averageSalesCycle.toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td className="py-2 px-4">Lost Deals</td>
                        <td className="py-2 px-4">{dealsData.lost?.count || 0}</td>
                        <td className="py-2 px-4">-</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default Summary;
